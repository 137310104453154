import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from 'sonner'
import { BASE_URL } from '../apiUrl';

const EmployeeForm = () => {
  const [employeeName, setEmployeeName] = useState('');
  const [workId, setWorkId] = useState('');
  const navigate = useNavigate();
  // const [notes, setNotes] = useState('');
  // const [location, setLocation] = useState('');

  const handleClockIn = async (employeeName, workId) => {
    console.log(employeeName, workId);
    try {
        const response = await axios.post(`${BASE_URL}/clockIn`, {name: employeeName, workId});
        console.log('Clock-in successful:', response.data.message)
        if(response.data.status){
          toast.success(response.data.message)
        }else{
          toast.error(response.data.message)
        }
       
        setEmployeeName("")
        setWorkId("")
    } catch (error) {
       console.error('Error clocking in employee', error) 
       alert('Failed to clock in')
    }
  };

  const handleClockOut = async (employeeName, workId) => {
    // Implement clock out functionality here
    try {
      const response = await axios.post(`${BASE_URL}/clockOut`, {name: employeeName, workId})
      console.log('Clock-out successful:', response.data.message)
      if(response.data.status){
        toast.success(response.data.message)
      }else{
        toast.error(response.data.message)
      }
      navigate("/");
    } catch (error) {
      console.error('Error clocking in employee', error.message) 
      alert('Failed to clock out')
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Perform form submission logic here
  //   console.log(employeeName, workId);
  //   console.log('Form submitted');
  // };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Employee Form</h2>
      <form>
        <div className="mb-4">
          <label htmlFor="employeeName" className="block text-sm font-medium text-gray-700">Employee Name:</label>
          <input
            type="text"
            id="employeeName"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            className="mt-1 px-4 py-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="employeeId" className="block text-sm font-medium text-gray-700">Employee ID:</label>
          <input
            type="text"
            id="employeeId"
            value={workId}
            onChange={(e) => setWorkId(e.target.value)}
            className="mt-1 px-4 py-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        {/* <div className="mb-4">
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">Notes:</label>
          <textarea
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="mt-1 px-4 py-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location:</label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="mt-1 px-4 py-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </div> */}
        <div className="flex justify-between">
          
          <button type="button" onClick={()=>handleClockIn(employeeName, workId)} className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">Clock In</button>
          <button type="button" onClick={()=>handleClockOut(employeeName, workId)} className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600">Clock Out</button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;
